import { AlertBox } from "../../../../../../components/AlertBox";
import { ExternalLink } from "../../../../../../components/ExternalLink";
import { FoldoutPanel } from "../../../../../../components/FoldoutPanel";
import { isMeasureSelectionGeoMicroFull } from "../../../../../../lib/application/state/stats/document-core/core-micro";
import { displayShortenedUrl } from "../../../../../../lib/application/url";
import { defined } from "../../../../../../lib/core/defined";
import { nonEmptyString } from "../../../../../../lib/core/nonEmptyString";
import {
  MeasureSelectionGeoMicroPartial,
  MeasureSpecMicro,
  PrimaryMeasureSelectionMicroFull,
} from "../../../../../../lib/domain/measure/definitions";

import "./MicroInfoTab.scss";

interface Props {
  primarySelection: PrimaryMeasureSelectionMicroFull | undefined;
  nonPrimarySelectionsPartial: MeasureSelectionGeoMicroPartial[];
}

export function MicroInfoTab(props: Props) {
  const { primarySelection, nonPrimarySelectionsPartial } = props;

  const nonPrimarySelections = nonPrimarySelectionsPartial.filter(
    isMeasureSelectionGeoMicroFull
  );
  return (
    <div className="micro-info-tab tab-content">
      {defined(primarySelection) && (
        <SingleMeasure measure={primarySelection.measure} />
      )}
      {nonPrimarySelections.map((selection) => (
        <SingleMeasure key={selection.measure.id} measure={selection.measure} />
      ))}
    </div>
  );
}

function SingleMeasure(props: { measure: MeasureSpecMicro }) {
  const {
    descrLong,
    source,
    extDescription,
    extDescriptionLong,
    extSource,
    sourceUrl,
    lastUpdate,
    publicComment,
    label,
  } = props.measure;
  return (
    <section className="single-measure-info">
      <h2>{descrLong}</h2>
      {defined(publicComment) && publicComment.length > 0 && (
        <>
          <h3>Infostats kommentar</h3>
          {publicComment.map((paragraph) => (
            <p>{paragraph}</p>
          ))}
        </>
      )}
      <h3>Källa: {source}</h3>
      <p>{descrLong}</p>
      {defined(extSource) && <h3>Källa: {extSource}</h3>}
      {defined(extDescription) && <p>{extDescription}</p>}
      {defined(extDescriptionLong) && <p>{extDescriptionLong}</p>}
      {(nonEmptyString(sourceUrl) || defined(lastUpdate)) && (
        <AlertBox>
          <div>
            <strong>Nedhämtat från källa</strong>: {lastUpdate}
          </div>
          <>
            {nonEmptyString(sourceUrl) && (
              <ExternalLink
                link={sourceUrl}
                text={displayShortenedUrl(sourceUrl)}
              ></ExternalLink>
            )}
          </>
        </AlertBox>
      )}
      {defined(label) && (
      <FoldoutPanel title=" ">
        <div>
          <p>{label}</p>
        </div>
      </FoldoutPanel>
      )}
    </section>
  );
}
